import { Grid, Flex, Heading } from "@telia/teddy";
import { Loading, Error } from "..";
import { TopupCategory } from "gql/graphql";
import { NORDIC_BALTIC_MINUTES_CODE } from "util/constants/topups";
import { NavigationCard, Props as NavigationCardProps } from "components/NavigationCard";
import { EXTERNAL_LINK } from "util/constants/externalLinks";
import { config } from "util/config";
import { useOrderSection } from "../../hooks";
import { useMobileDashboardContext } from "../../../../context/MobileDashboardContext";

type OrderSectionCard = NavigationCardProps & { condition: boolean };

export function OrderSection() {
  const { openSelectedTopupDrawer, isPrepaid } = useMobileDashboardContext();

  const {
    loading,
    error,
    refetch,
    hasDataBoosts,
    hasDataPackages,
    hasFreeDataBoosts,
    hasFreeDataPackages,
    isRoamingBlocked,
    isActiveNordicBalticMinutes,
    hasNordicBalticMinutesProduct,
    canOrderNordicBalticMinutesPrepaidProduct,
    freeDataBoostsVoucherCount,
    freeDataPackagesVoucherCount,
  } = useOrderSection();

  const cardData: OrderSectionCard[] = [
    {
      condition: hasDataBoosts,
      icon: "getting-started",
      title: "Data Boost",
      description: "Gir deg ubegrenset med data, fra kjøp og til pakken utgår.",
      badge: hasFreeDataBoosts
        ? {
            icon: "present",
            variant: "special",
            text: `${freeDataBoostsVoucherCount} stk. kostnadsfri`,
          }
        : undefined,
      onClick: () => openSelectedTopupDrawer(TopupCategory.DataBoost),
    },
    {
      condition: !isRoamingBlocked,
      icon: "plane",
      title: "Datapakker utland",
      description: "Datapakker utenfor EU, EØS, Sveits og Storbritannia.",
      onClick: () => openSelectedTopupDrawer(TopupCategory.Roaming),
    },
    {
      condition: hasDataPackages,
      icon: "present",
      title: "Datapakker",
      description: "Datapakker varer i 30 dager fra kjøp, eller til dataen er brukt opp.",
      badge: hasFreeDataPackages
        ? {
            icon: "present",
            variant: "special",
            text: `${freeDataPackagesVoucherCount} stk. kostnadsfri`,
          }
        : undefined,
      onClick: () => openSelectedTopupDrawer(TopupCategory.Domestic),
    },
    {
      condition: hasNordicBalticMinutesProduct,
      icon: "phone-ringing",
      title: "Ring Norden & Baltikum",
      description: "1000 inkluderte ringeminutter i måneden fra Norge til Norden og Baltikum.",
      badge: isActiveNordicBalticMinutes
        ? {
            icon: "check-circle",
            variant: "success",
            text: "Aktiv",
          }
        : undefined,
      onClick: () => openSelectedTopupDrawer(NORDIC_BALTIC_MINUTES_CODE),
    },
  ];

  if (loading) return <Loading />;
  if (error) return <Error title="Bestill" refetch={refetch} />;
  // For prepaid subscription if the user already has active Nordic/Baltic minutes product with minutes balance, no need show the order section
  // Also should be shown only with new prepaid features.
  if (isPrepaid && !(canOrderNordicBalticMinutesPrepaidProduct && config.showNewPrepaidPage)) return null;

  return (
    <Flex direction="column" gap="gutter-sm">
      <Heading as="h3" variant="title-100">
        Bestill
      </Heading>

      <Grid columns={{ xxs: "1", md: "2" }} gap="gutter-xl">
        {isPrepaid && canOrderNordicBalticMinutesPrepaidProduct ? (
          <NavigationCard
            icon="phone-ringing"
            title="Ring Norden & Baltikum"
            description="1000 inkluderte ringeminutter i måneden fra Norge til Norden og Baltikum."
            link={EXTERNAL_LINK.PREPAID_ORDER_TOPUPS}
          />
        ) : (
          <>
            {cardData.map(
              (card) =>
                card.condition && (
                  <NavigationCard
                    key={card.title}
                    link={card.link}
                    icon={card.icon}
                    title={card.title}
                    description={card.description}
                    badge={card.badge}
                    onClick={card.onClick}
                  />
                )
            )}
          </>
        )}
      </Grid>
    </Flex>
  );
}
